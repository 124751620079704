import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Link } from 'gatsby';
import Logo from './Logo';
import NavigationSection from './NavigationSection';
import { StaticImage } from 'gatsby-plugin-image';

const GrayBG = styled.header`
background-color: #8e9eab;
background: -webkit-linear-gradient(to right, #eef2f3, #8e9eab);
background: linear-gradient(to right, #eef2f3, #8e9eab);
  .usa-nav {
    background-color: #fff;
    border-bottom: 1px solid #d6d7d9;
  }
  .usa-logo {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    align-content: center;
    line-height: 1;
    max-width: 80%;
  }

  .usa-logo__amedd-logo {
    height: auto;
    width: 70px;
    margin-right: 10px;
  }

  .usa-logo__amedd-logo__army {
    height: auto;
    width: 55px;
    margin-right: 5px;
  }

  .usa-logo__webaward-logo {
    height: auto;
    width: 3rem;
    float: right;
    margin-top: 2rem;
    margin-bottom: 1rem;
    margin-right: 20px;
  }

  .years {
    width: 5rem;
  }

  .usa-logo__webaward-logo p {
    line-height: 8px;
    margin: 5px;
    color: white;
    font-size: 8px;
    margin-top: 0;
    text-align: left;
    text-transform: uppercase;
  }

  a.usa-logo__webaward-logo {
    text-decoration: none !important;
  }

  .usa-megamenu .usa-col {
    flex: 1 1 auto;
  }

  @media screen and (max-width: 951px) {
    .usa-logo__amedd-logo__army,
    .usa-logo-text__secondary,
    .usa-logo-text__primary,
    .usa-logo__webaward-logo {
      display: none;
    }

    .years {
      display: none;
      width: 86px;
      margin-top: -11px;
    }

    .usa-logo__amedd-logo {
      height: auto;
      width: 30px;
      margin-top: 6px;
    }

    .usa-logo-text__mobile {
      margin-left: 5px;
      display: initial !important;
    }
  }
`;

const Navigation = ({ siteTitle }) => {
  // FSP@BTS: Adds search.gov key and snippet
  useEffect(() => {
    var usasearch_config = { siteHandle: 'medcoe' };

    var script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = '//search.usa.gov/javascripts/remote.loader.js';
    document.getElementsByTagName('head')[0].appendChild(script);
  }, []);

  return (
    <GrayBG className='usa-header usa-header--extended' role='banner'>
      <a
        className='usa-logo__webaward-logo'
        href='https://www.webaward.org/winner/35913/medcoe-wins-2021-webaward-for-medcoe.html#.YVxzK7hOkUE'
        target='_blank'
      >
        <StaticImage
          src='../../images/webaward2021.jfif'
          alt='2021 web award'
          width={40}
          style={{borderRadius: '8px'}}
        />
        <p className='mt-1'>Government Standard of Excellence</p>
      </a>
      <Logo siteTitle={siteTitle} />
      <nav aria-label='Primary navigation' className='usa-nav'>
        <div className='usa-nav__inner'>
          <button className='usa-nav__close'>
            <StaticImage src='../../images/close.svg' alt='close' />
          </button>
          <ul className='usa-nav__primary usa-accordion'>
            <NavigationSection navCategory='Students' />
            <NavigationSection navCategory='Family' />
            <NavigationSection navCategory='Staff' />
            <NavigationSection navCategory='Supported Agencies' />
            <NavigationSection navCategory='Start Your Army Story' />
          </ul>

          {/* Search */}
          <div
            className='usa-nav__secondary'
            css={css`
              bottom: 0.5em;
            `}
          >
            <form
              id='search_form'
              action='https://search.usa.gov/search'
              acceptCharset='UTF-8'
              method='get'
              className='usa-search usa-search--small'
              role='search'
            >
              <label htmlFor='query' className='usa-sr-only'>
                Search small
              </label>
              <input
                className='usagov-search-autocomplete'
                //Added this. 
                placeholder="Search Here..."
                //End
                name='query'
                id='query'
                autoComplete='off'
              />
              <input name='utf8' type='hidden' value='&#x2713;' />
              <input type='hidden' name='affiliate' id='affiliate' value='medcoe' />
            </form>
          </div>
        </div>
      </nav>
    </GrayBG>
  );
};

Navigation.propTypes = {
  siteTitle: PropTypes.string,
};

Navigation.defaultProps = {
  siteTitle: `MEDCoE`,
};

export default Navigation;
