import React from 'react';
import PropTypes from 'prop-types';
import NavLinksJson from '../../hooks/use-nav-items';
import NavLinkList from './NavLinkList';

export default function NavigationSection({ navCategory }) {
  const NavLinksData = NavLinksJson();
  return (
    <li className='usa-nav__primary-item'>
      <button
        className='usa-accordion__button usa-nav__link'
        aria-expanded='false'
        aria-controls={`extended-mega-nav-${navCategory.replace(/ /g, '')}`}
      >
        <span>{navCategory}</span>
      </button>
      <div
        id={`extended-mega-nav-${navCategory.replace(/ /g, '')}`}
        className='usa-nav__submenu usa-megamenu'
        hidden
      >
        <div className='grid-row grid-gap-1'>
          <NavLinkList
            NavLinksData={NavLinksData.filter(
              navLink => navLink.category === navCategory && navLink.column === 'left'
            ).sort((a, b) => {
              if (a.sortOrder < b.sortOrder) {
                return -1;
              }
              return 1;
            })}
            category={navCategory}
            column='left'
          />
          <NavLinkList
            NavLinksData={NavLinksData.filter(
              navLink => navLink.category === navCategory && navLink.column === 'middle'
            ).sort((a, b) => {
              if (a.sortOrder < b.sortOrder) {
                return -1;
              }
              return 1;
            })}
            category={navCategory}
            column='middle'
          />
          <NavLinkList
            NavLinksData={NavLinksData.filter(
              navLink => navLink.category === navCategory && navLink.column === 'third'
            )}
            category={navCategory}
            column='third'
          />
          {navCategory !== 'Start Your Army Story' ?
          <NavLinkList
            NavLinksData={NavLinksData.filter(
              navLink => navLink.category === 'Default' && navLink.column === 'right'
            ).sort((a, b) => {
              if (a.sortOrder < b.sortOrder) {
                return -1;
              }
              return 1;
            })}
            category='Default'
            column='right'
          />
        : null}
        </div>
      </div>
    </li>
  );
}

NavigationSection.propTypes = {
  navCategory: PropTypes.string.isRequired
};
